<template>
  <div class="mb-1">
    <b-form @submit.prevent>
      <b-row>
        <b-col md="12">
          <div class="title">
            Datos Familiares
          </div>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Tipo de vivienda"
            label-for="type_of_house"
          >
            <b-form-input
              id="type_of_house"
              v-model="formData.type_of_house"
              placeholder="Tipo de vivienda"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Ciudad de residencia"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="formData.city"
              placeholder="Ciudad de residencia"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="En caso de emergencia comunicarse con"
            label-for="emergency_contact"
          >
            <b-form-input
              id="emergency_contact"
              v-model="formData.emergency_contact"
              placeholder="Contacto de emergencia"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Parentesco"
            label-for="kinship"
          >
            <b-form-input
              id="kinship"
              v-model="formData.kinship"
              placeholder="Parentesco"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Teléfono"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="formData.phone"
              type="number"
              placeholder="Teléfono"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Teléfono 2"
            label-for="phone2"
          >
            <b-form-input
              id="phone2"
              v-model="formData.phone2"
              type="number"
              placeholder="Teléfono 2"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <div class="title">
            Datos del Contrato
          </div>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Fecha de ingreso"
            label-for="date_of_entry"
          >
            <b-form-input
              v-model="formData.date_of_entry"
              type="date"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Fecha de retiro"
            label-for="retirement_date"
          >
            <b-form-input
              v-model="formData.retirement_date"
              type="date"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Tipo de contrato"
            label-for="type_of_contract"
          >
            <div class="form-label-group">
              <b-form-input
                id="type_of_contract"
                v-model="formData.type_of_contract"
                placeholder="Tipo de contrato"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="EPS"
            label-for="eps"
          >
            <div class="form-label-group">
              <b-form-input
                id="eps"
                v-model="formData.eps"
                placeholder="EPS"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Pensión"
            label-for="pension"
          >
            <div class="form-label-group">
              <b-form-input
                id="pension"
                v-model="formData.pension"
                placeholder="pensión"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="ARL"
            label-for="arl"
          >
            <div class="form-label-group">
              <b-form-input
                id="arl"
                v-model="formData.arl"
                placeholder="ARL"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Talla de camisa"
            label-for="shirt_size"
          >
            <div class="form-label-group">
              <b-form-input
                id="shirt_size"
                v-model="formData.shirt_size"
                placeholder="Talla de camisa"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Talla de pantalon"
            label-for="pant_size"
          >
            <div class="form-label-group">
              <b-form-input
                id="pant_size"
                v-model="formData.pant_size"
                placeholder="Talla de pantalon"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Talla de botas"
            label-for="shoe_size"
          >
            <div class="form-label-group">
              <b-form-input
                id="shoe_size"
                v-model="formData.shoe_size"
                placeholder="Talla de camisa"
              />
            </div>
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col
          v-if="!isDetail"
          md="12"
          class="text-right"
        >
          <b-button
            type="button"
            variant="primary"
            :disabled="isBusy || $v.$invalid"
            @click="saveData"
          >
            <v-icon
              name="save"
            />
            Guardar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
  import {
    BForm,
    BFormGroup,
    BButton,
    BCol,
    BFormInput,
    BRow,
  } from 'bootstrap-vue'
  import { required } from 'vuelidate/lib/validators'
  import CRUDMixin from '@/mixins/CRUDMixin'

  export default {
    inject: ['datafieldsRepository', 'employeesRepository'],
    components: {
      BForm,
      BFormGroup,
      BButton,
      BCol,
      BFormInput,
      BRow,
    },
    mixins: [CRUDMixin],

    props: {
      record: {
        type: Object,
        default: null,
      },
      isDetail: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        formData: {
          type_of_house: '',
          city: '',
          emergency_contact: '',
          kinship: '',
          phone: '',
          phone2: '',
          date_of_entry: '',
          retirement_date: '',
          type_of_contract: '',
          eps: '',
          pension: '',
          arl: '',
          shirt_size: '',
          pant_size: '',
          shoe_size: '',
        },
        repository: 'employeesRepository',
        isBusy: false,
      }
    },

    validations: {
      formData: {
          city: {
            required,
          },
          emergency_contact: {
            required,
          },
          kinship: {
            required,
          },
          phone: {
            required,
          },
          date_of_entry: {
            required,
          },
          retirement_date: {
            required,
          },
          type_of_contract: {
            required,
          },
          eps: {
            required,
          },
          pension: {
            required,
          },
          arl: {
            required,
          },
        },
    },

    async mounted() {
      const me = this

      if (me.record) {
        me.formData = { ...me.record }
      }
    },

    methods: {
      save() {
        this.$emit('save')
      },
    },
  }
</script>

<style lang="scss" scoped>
.title {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: bold;
  color: #5E5878;
}
</style>

<template>
  <div>
    <b-form @submit.prevent>
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Nombre*"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="formData.name"
              placeholder="Nombre"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" />
        <b-col
          md="6"
          class="text-right"
        >
          <b-button
            type="button"
            variant="primary"
            :disabled="isBusy || $v.$invalid"
            @click="saveData"
          >
            <b-spinner
              v-if="isBusy"
              small
            />
            <v-icon
              v-else
              name="save"
            />
            Guardar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
  import {
    BForm,
    BFormGroup,
    BButton,
    BCol,
    BFormInput,
    BRow,
    BSpinner,
  } from 'bootstrap-vue'
  import { required } from 'vuelidate/lib/validators'
  import { v4 as uuidv4 } from 'uuid'

  export default {
    name: 'AddEditSpecialtyForm',
    components: {
      BForm,
      BFormGroup,
      BButton,
      BCol,
      BFormInput,
      BRow,
      BSpinner,
    },
    props: {},

    data() {
      return {
        formData: {
          name: '',
          is_active: true,
        },
        isBusy: false,
      }
    },

    validations: {
      formData: {
        name: {
          required,
        },
      },
    },

    methods: {
      async saveData() {
        /* const me = this
        try {
          me.isBusy = true
          const id = uuidv4()
          await me.specialtiesRepository.save(id, me.formData)
          me.$emit('saveSpecialty')
          this.$bvModal.hide('specialty-modal')
          me.$bvToast.toast('Registro guardado con éxito', {
					title: 'Éxito',
					toaster: 'b-toaster-bottom-right',
					variant: 'success',
					solid: true,
					appendToast: true,
				})
        } catch (error) {
          me.$bvToast.toast('Error', {
            title: 'Ocurrió un error al guardar el registro',
            toaster: 'b-toaster-bottom-right',
            variant: 'danger',
            solid: true,
            appendToast: true,
          })
        } finally {
          me.isBusy = false
        } */
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>

<template>
  <div>
    <b-card
      no-body
    >
      <base-actions-table
        :columns="fields"
        :records="records"
        :busy="isBusy"
        :is-processing="isProcessing"
        :module="module"
        @creating="handleCreating"
        @editing="handleEditing"
        @deleting="handleDeleting"
      >
        <template #cell(photo)>
          <b-avatar variant="primary">
            <feather-icon icon="HomeIcon" />
          </b-avatar>
        </template>
        <template v-slot:extendedactions="data">
          <b-button
            v-if="hasPermission(`${module}.details`)"
            variant="info"
            class="btn-icon mr-25"
            size="sm"
            @click.prevent="handleDetail(data.data.item)"
          >
            <feather-icon
              icon="EyeIcon"
            />
          </b-button>
          <b-button
            v-if="hasPermission(`${module}.infoadditional`)"
            variant="warning"
            class="btn-icon mr-25"
            size="sm"
            @click.prevent="handleinfoadditional(data.data.item)"
          >
            <feather-icon
              icon="PlusIcon"
            />
          </b-button>
        </template>
      </base-actions-table>
    </b-card>
    <b-modal
      id="employee-modal"
      title="Agregar/Editar empleado"
      size="lg"
      hide-footer
    >
      <b-card-text>
        <add-employee-form
          ref="form"
          :record="selectedRecord"
          :is-detail="isDetail"
          @save="save"
          @edit="edit"
        />
      </b-card-text>
    </b-modal>
    <b-modal
      id="info-additional-modal"
      title="Agregar/Editar Información adicional"
      size="lg"
      hide-footer
    >
      <b-card-text>
        <add-employee-additional-info-form
          ref="form"
          :record="selectedRecord"
          :is-detail="isDetail"
          @save="save"
          @edit="edit"
        />
      </b-card-text>
    </b-modal>
    <b-modal
      id="add-device-modal"
      :title="!selectedRecord ? '' : 'Dispositivo asociado - ' + selectedRecord.full_name"
      hide-footer
    >
      <b-card-text>
        <associate-device @add="addDevice" />
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
  import {
    BAvatar, BModal, BCardText, BCard, BButton,
  } from 'bootstrap-vue'
  import AddEmployeeForm from './AddEmployeeForm.vue'
  import AddEmployeeAdditionalInfoForm from './AddEmployeeAdditionalInfoForm'
  import AssociateDevice from './DeviceForm/AssociateDevice.vue'
  import CRUDMixin from '@/mixins/CRUDMixin'
  import PermissionMixin from '@/mixins/PermissionMixin'
  import BaseActionsTable from '@/components/base/BaseActionsTable'

  export default {
    name: 'Employee',
    inject: ['employeesRepository'],
    components: {
      BCard,
      BAvatar,
      BModal,
      BCardText,
      BButton,
      AddEmployeeForm,
      AddEmployeeAdditionalInfoForm,
      AssociateDevice,
      BaseActionsTable,
    },
    mixins: [CRUDMixin, PermissionMixin],
    data() {
      return {
        fields: [
          {
            key: 'photo',
            label: 'Foto',
          },
          {
            key: 'full_name',
            label: 'Nombre',
            filterByFormatted: true,
          },
          {
            key: 'nid',
            label: 'Cédula',
          },
          {
            key: 'profession.long_name',
            label: 'Tipo de profesión',
          },
          {
            key: 'area',
            label: 'Área asignada',
          },
          {
            key: 'turn',
            label: 'Turno',
          },
          {
            key: 'showturn',
            label: 'Conosultar rondas de turno',
          },
          {
            key: 'device_name',
            label: 'Asignar dispositivo RIFD/QR',
          },
          {
            key: 'actions',
            label: 'Acciones',
          },
        ],
        records: [],
        selectedRecord: null,
        repository: 'employeesRepository',
        module: 'employee',
        isBusy: false,
        isDetail: false,
      }
    },

    async mounted() {
      const me = this
      await me.handleLoadData()
    },

    methods: {
      assingDevice(data) {
        this.selectedEmployee = data
      },

      addDevice() {
        this.record[0].device = 'Device Tal'
      },

      handleCreating() {
        this.selectedRecord = null
        this.isDetail = false
        this.$bvModal.show('employee-modal')
      },

      handleEditing(record) {
        this.selectedRecord = record
        this.isDetail = false
        this.$bvModal.show('employee-modal')
      },

      handleDetail(record) {
        this.selectedRecord = record
        this.isDetail = true
        this.$bvModal.show('employee-modal')
      },

      handleDeleting(record) {
        this.del(record.id)
      },

      handleinfoadditional(record) {
        this.$bvModal.show('info-additional-modal')
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>

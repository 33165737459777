<template>
  <div class="p-2">
    <b-form @submit.prevent>
      <b-row>
        <b-col md="6">
          <b-row>
            <b-form-group
              label="Tipo dispositivo"
              label-for="device-type"
            >
              <b-form-input
                id="device-type"
                placeholder="QR/RIFD"
              />
            </b-form-group>
          </b-row>
          <b-row>
            <b-form-group
              label="MAC dispositivo"
              label-for="device-mac"
            >
              <b-form-input
                id="device-type"
                placeholder="Selecciona MAC dispositivo"
              />
            </b-form-group>
          </b-row>
        </b-col>
        <b-col md="6">
          <div>Código QR</div>
          <div>
            <v-icon
              name="qrcode"
              scale="5"
              class="mr-2"
            />
            <v-icon
              name="download"
              scale="2"
            />
          </div>
        </b-col>

        <!-- submit and reset -->
        <b-col>
          <b-row>
            <b-col md="6" />
            <b-col md="6">
              <b-button
                type="button"
                variant="primary"
                class="mr-1"
                @click="handleAdd"
              >
                <v-icon name="save" />
                Añadir
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
  import {
    BForm,
    BFormGroup,
    BButton,
    BCol,
    BFormInput,
    BRow,
  } from 'bootstrap-vue'

  export default {
    components: {
      BForm,
      BFormGroup,
      BButton,
      BCol,
      BFormInput,
      BRow,
    },

    data() {
      return {}
    },

    methods: {
      handleAdd() {
        this.$emit('add')
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>

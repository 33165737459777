<template>
  <div class="mb-1">
    <b-form @submit.prevent>
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Nombre completo"
            label-for="full_name"
          >
            <b-form-input
              id="full_name"
              v-model="formData.full_name"
              :disabled="isDetail"
              placeholder="Nombre completo"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Tipo de identificación"
            label-for="document_type_id"
          >
            <div class="form-label-group">
              <v-select
                v-model="formData.document_type_id"
                label="name"
                placeholder="Seleccione"
                :disabled="isDetail"
                :options="documentTypes"
                :reduce="(option) => option.value"
              >
                <div slot="no-options">
                  No hay opciones
                </div>
              </v-select>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Nº de Identificación"
            label-for="nid"
          >
            <b-form-input
              id="nid"
              v-model="formData.nid"
              :disabled="isDetail"
              placeholder="Nº de Identificación"
              @blur="$v.formData.nid.$touch()"
            />
            <span
              v-if="$v.formData.nid.$error && !$v.formData.nid.numeric"
              class="help-block text-danger"
            >Ingrese solo números.</span>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Lugar de expedición"
            label-for="nid-expedition-place"
          >
            <b-form-input
              id="nid-expedition-place"
              v-model="formData.nid_expedition_place"
              :disabled="isDetail"
              placeholder="Lugar de expedición"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Fecha de expedición"
                label-for="nid_expedition_date"
              >
                <b-form-input
                  v-model="formData.nid_expedition_date"
                  :disabled="isDetail"
                  type="date"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Fecha de nacimiento"
                label-for="date-of-birth"
              >
                <b-form-input
                  v-model="formData.date_of_birth"
                  :disabled="isDetail"
                  type="date"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6">
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Edad"
                label-for="age"
              >
                <div class="form-label-group">
                  <b-form-input
                    id="age"
                    v-model="formData.age"
                    :disabled="isDetail"
                    placeholder="Edad"
                    @blur="$v.formData.age.$touch()"
                  />
                  <span
                    v-if="$v.formData.age.$error && !$v.formData.age.numeric"
                    class="help-block text-danger"
                  >Ingrese solo números.</span>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Sexo"
                label-for="sex"
              >
                <div class="form-label-group">
                  <v-select
                    v-model="formData.gender_id"
                    :disabled="isDetail"
                    label="name"
                    placeholder="Seleccione"
                    :options="sex"
                    :reduce="(option) => option.value"
                  >
                    <div slot="no-options">
                      No hay opciones
                    </div>
                  </v-select>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6">
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Estado civil"
                label-for="civil_status"
              >
                <div class="form-label-group">
                  <v-select
                    v-model="formData.civil_status_id"
                    :disabled="isDetail"
                    label="name"
                    placeholder="Seleccione"
                    :options="civilStatus"
                    :reduce="(option) => option.value"
                  >
                    <div slot="no-options">
                      No hay opciones
                    </div>
                  </v-select>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Tipo de sangre"
                label-for="blood-type"
              >
                <div class="form-label-group">
                  <v-select
                    v-model="formData.blood_type_id"
                    :disabled="isDetail"
                    label="name"
                    placeholder="Seleccione"
                    :options="bloodType"
                    :reduce="(option) => option.value"
                  >
                    <div slot="no-options">
                      No hay opciones
                    </div>
                  </v-select>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Profesión"
            label-for="position"
          >
            <div class="form-label-group d-flex">
              <v-select
                v-model="formData.profession_id"
                :disabled="isDetail"
                label="name"
                placeholder="Seleccione"
                :options="position"
                :reduce="(option) => option.value"
                class="select__"
              >
                <div slot="no-options">
                  No hay opciones
                </div>
              </v-select>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="btn-icon"
                @click="handleCreatingProfession"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Especialidad"
            label-for="specialty_id"
          >
            <div class="form-label-group d-flex">
              <v-select
                v-model="formData.specialty_id"
                :disabled="isDetail"
                label="name"
                placeholder="Seleccione"
                :options="specialties"
                :reduce="(option) => option.value"
                class="select__"
              >
                <div slot="no-options">
                  No hay opciones
                </div>
              </v-select>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="btn-icon"
                @click="handleCreatingSpecialty"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Dirección"
            label-for="address"
          >
            <div class="form-label-group">
              <b-form-input
                id="address"
                v-model="formData.address"
                :disabled="isDetail"
                placeholder="Dirección"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Teléfono"
                label-for="phone"
              >
                <div class="form-label-group">
                  <b-form-input
                    id="phone"
                    v-model="formData.phone"
                    :disabled="isDetail"
                    placeholder="Teléfono"
                    @blur="$v.formData.phone.$touch()"
                  />
                  <span
                    v-if="
                      $v.formData.phone.$error && !$v.formData.phone.numeric
                    "
                    class="help-block text-danger"
                  >Ingrese solo números.</span>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Celular"
                label-for="cellphone"
              >
                <div class="form-label-group">
                  <b-form-input
                    id="cellphone"
                    v-model="formData.cellphone"
                    :disabled="isDetail"
                    placeholder="Celular"
                    @blur="$v.formData.cellphone.$touch()"
                  />
                  <span
                    v-if="
                      $v.formData.cellphone.$error &&
                        !$v.formData.cellphone.numeric
                    "
                    class="help-block text-danger"
                  >Ingrese solo números.</span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Correo electrónico"
            label-for="email"
          >
            <div class="form-label-group">
              <b-form-input
                id="email"
                v-model="formData.email"
                :disabled="isDetail"
                placeholder="Correo electrónico"
                @blur="$v.formData.email.$touch()"
              />
              <span
                v-if="$v.formData.email.$error && !$v.formData.email.email"
                class="help-block text-danger"
              >Ingrese una dirección de correo válida.</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Rol*"
            label-for="rol"
          >
            <v-select
              id="rol"
              v-model="formData.profile_id"
              :options="profiles"
              :reduce="option => option.id"
              label="name"
              placeholder="Seleccione rol"
            >
              <div slot="no-options">
                No hay opciones
              </div>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col
          v-if="!isDetail"
          md="12"
          class="text-right"
        >
          <b-button
            type="button"
            variant="primary"
            :disabled="isBusy || $v.$invalid"
            @click="saveData"
          >
            <v-icon name="save" />
            Guardar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <b-modal
      id="specialty-modal"
      title="Agregar Especialidad"
      hide-footer
    >
      <add-specialty-form @saveSpecialty="loadSpecialties" />
    </b-modal>
    <b-modal
      id="profession-modal"
      title="Agregar Profesión"
      hide-footer
    >
      <add-profession-form />
    </b-modal>
  </div>
</template>

<script>
import {
	BForm,
	BFormGroup,
	BButton,
	BCol,
	BFormInput,
	BRow,
	BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required, numeric, email } from 'vuelidate/lib/validators'
import moment from 'moment'
import AddSpecialtyForm from './AddSpecialtyForm'
import AddProfessionForm from './AddProfessionForm'
import CRUDMixin from '@/mixins/CRUDMixin'

export default {
	inject: [
		'datafieldsRepository',
		'employeesRepository',
		'specialtiesRepository',
		'typeProfileRepository',
	],
	components: {
		BForm,
		BFormGroup,
		BButton,
		BCol,
		BFormInput,
		BRow,
		BModal,
		vSelect,
		AddSpecialtyForm,
		AddProfessionForm,
	},
	directives: {
		Ripple,
	},
	mixins: [CRUDMixin],

	props: {
		record: {
			type: Object,
			default: null,
		},
		isDetail: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			formData: {
				full_name: '',
				nid: '',
				date_of_birth: null,
				nid_expedition_place: null,
				nid_expedition_date: null,
				address: null,
				phone: null,
				email: null,
				civil_status_id: null,
				blood_type_id: null,
				profession_id: null,
				document_type_id: null,
				workshift_id: null,
				device_id: null,
				specialty_id: null,
				gender_id: null,
				age: null,
				cellphone: null,
        profile_id: null,
			},
			repository: 'employeesRepository',
			isBusy: false,
			documentTypes: [],
			sex: [],
			civilStatus: [],
			bloodType: [],
			position: [],
			specialties: [],
			profiles: [],
		}
	},

	validations: {
		formData: {
			full_name: {
				required,
			},
			nid: {
				required,
				numeric,
			},
			email: {
				required,
				email,
			},
			profession_id: {
				required,
			},
			document_type_id: {
				required,
			},
			cellphone: {
				required,
				numeric,
			},
			phone: {
				numeric,
			},
			age: {
				numeric,
			},
      profile_id: {
        required,
      },
		},
	},

	async mounted() {
		const me = this

		const sexResult = await me.datafieldsRepository.getAll('gender')
		const civilStatusResult = await me.datafieldsRepository.getAll(
			'civil_status',
		)
		const bloodTypeResult = await me.datafieldsRepository.getAll('blood_type')
		const positionResult = await me.datafieldsRepository.getAll('professions')
		const identificationTypesResult = await me.datafieldsRepository.getAll(
			'document_type',
		)
		const { data } = await me.typeProfileRepository.getAll()

		me.sex = sexResult.data.map(item => ({
			value: item.id,
			name: item.longName,
		}))
		me.civilStatus = civilStatusResult.data.map(item => ({
			value: item.id,
			name: item.longName,
		}))
		me.bloodType = bloodTypeResult.data.map(item => ({
			value: item.id,
			name: item.longName,
		}))
		me.position = positionResult.data.map(item => ({
			value: item.id,
			name: item.longName,
		}))
		me.documentTypes = identificationTypesResult.data.map(item => ({
			value: item.id,
			name: item.longName,
		}))
		data.forEach(el => {
			if (el.id !== '2734e277-e007-4b55-8ec8-21dc99a59d04') {
				me.profiles.push({ id: el.id, name: el.rol + ' ' + el.position })
			}
		})

		await me.loadSpecialties()

		if (me.record) {
			me.formData = {
				...me.record,
				nid_expedition_date: me.record.nid_expedition_date
					? moment(me.record.nid_expedition_date.date).format('YYYY-MM-DD')
					: null,
				date_of_birth: me.record.date_of_birth
					? moment(me.record.date_of_birth.date).format('YYYY-MM-DD')
					: null,
			}
		}
	},

	methods: {
		prepareDataForSubmit() {
			const me = this

			return {
				...me.formData,
				age: parseInt(me.formData.age, 10),
				nid_expedition_date: me.formData.nid_expedition_date
					? moment(me.formData.nid_expedition_date).format('DD-MM-YYYY')
					: null,
				date_of_birth: me.formData.date_of_birth
					? moment(me.formData.date_of_birth).format('DD-MM-YYYY')
					: null,
			}
		},

		save() {
			this.$emit('save')
		},

		async loadSpecialties() {
			const me = this
			const specialtiesResult = await me.specialtiesRepository.getAll()
			me.specialties = specialtiesResult.data.map(item => ({
				value: item.id,
				name: item.name,
			}))
		},

		handleCreatingSpecialty() {
			this.$bvModal.show('specialty-modal')
		},
		handleCreatingProfession() {
			this.$bvModal.show('profession-modal')
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.select__ {
	width: 90%;
	margin-right: 5px;
}
</style>
